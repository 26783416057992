<template>
  <div id="Roadmap">
    <div class="text-center text-white w-8/12 py-10 mx-auto bg-clip-padding backdrop-filter backdrop-blur-xl bg-opacity-60 border border-gray-200  rounded-lg">
      <h2 class="text-6xl text-white">Roadmap</h2>
      <p class="text-white my-3">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed id nunc lorem. Integer et dignissim felis. In hac habitasse platea dictumst. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Fusce sit amet sollicitudin nulla. Aliquam sed consectetur ante, eu faucibus justo. Maecenas non fringilla mi.</p>
    </div>
    <div class="px-4 w-8/12 mx-auto">
      <div class="text-left my-10" v-for="(p, idx) in roadmap" :key="idx">
        <p class="text-4xl font-bold text-white text-left -ml-10">{{p.header}}</p>
        <p class="text-2xl text-gray-300 text-left ml-4">{{p.paragraph}}</p>
      </div>
    </div>
  </div>
</template>
<script>
// import {mapGetters,mapMutations} from "vuex"
// import {db} from '@/_firebase/init'

export default {
  components: {
  },
  name: 'Roadmap',
  data(){
    return{
      roadmap:[
        {
          header: 'Private community chat and NFT Videos',
          paragraph: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed id nunc lorem. Integer et dignissim felis. In hac habitasse plate. Sit amet sollicitudin nulla. Aliquam sed consectetur ante, eu fa'
        },
        {
          header: 'FREE Mint to future JRNY NFT sets',
          paragraph: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed id nunc lorem. Integer et dignissim felis. In hac habitasse plate. Sit amet sollicitudin nulla. Aliquam sed consectetur ante, eu fa'
        },
        {
          header: 'Bored Ape Yacht Club and other NFT Giveaways',
          paragraph: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed id nunc lorem. Integer et dignissim felis. In hac habitasse plate. Sit amet sollicitudin nulla. Aliquam sed consectetur ante, eu fa'
        },
        {
          header: 'Community Grant Fund of $100,000',
          paragraph: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed id nunc lorem. Integer et dignissim felis. In hac habitasse plate. Sit amet sollicitudin nulla. Aliquam sed consectetur ante, eu fa'
        },
        {
          header: 'JRNY CLUB in the Metaverse',
          paragraph: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed id nunc lorem. Integer et dignissim felis. In hac habitasse plate. Sit amet sollicitudin nulla. Aliquam sed consectetur ante, eu fa'
        },
        {
          header: 'Establish Quality Partnerships',
          paragraph: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed id nunc lorem. Integer et dignissim felis. In hac habitasse plate. Sit amet sollicitudin nulla. Aliquam sed consectetur ante, eu fa'
        },
        {
          header: 'JRNY NFT Launchpad',
          paragraph: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed id nunc lorem. Integer et dignissim felis. In hac habitasse plate. Sit amet sollicitudin nulla. Aliquam sed consectetur ante, eu fa'
        },
        {
          header: '2022 NFT BLAST OFF',
          paragraph: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed id nunc lorem. Integer et dignissim felis. In hac habitasse plate. Sit amet sollicitudin nulla. Aliquam sed consectetur ante, eu fa'
        },
      ]
    }
  },
  methods:{

  },
  created(){
  }
}
</script>
<style scoped>
#Roadmap{
  min-height: 100vh;
  padding: 4rem 0;
  background-color: #ff7788;
}
</style>
