<template>
<div
    class="relative bg-indigo-900 relative w-full min-h-1/2 bg-no-repeat bg-cover bg-center py-10"
    :style="{'background-image': 'url(' + require('@/assets/background.svg') + ')'}">

    <div class="flex justify-between w-11/12 mx-auto p-3">
        <div class="flex">
            <img src="@/assets/derp.png" class="w-12 h-12 mr-3"/>
            <p class="text-white text-5xl font-bold ">Derpy Dragons</p>
        </div>
        <ul class="text-white flex justify-between">
            <li class="mx-3 text-3xl font-bold" >Home</li>
            <li class="mx-3 text-3xl font-bold" >FAQ</li>
            <li class="mx-3 text-3xl font-bold" >Mint</li>
        </ul>
    </div>

    <div class="flex w-10/12 mx-auto py-20 items-center justify-between">
        <div class="w-1/2 m-4 lg:w-4/12">
            <img src="@/assets/derp.png"  class="w-full rounded-full border-8 border-white"/>
        </div>
        <div class="w-1/2 text-right">
            <h1 class="text-pink-400 text-3xl font-bold">Welcome to Derpy Dragons</h1>
            <p class="text-sm italic text-xl text-white">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed ac felis congue, accumsan velit vitae, vestibulum elit. Vestibulum nec maximus ipsum, vel mollis libero. Quisque accumsan rutrum massa, eu gravida libero aliquam id. Aliquam dapibus consequat lacus, sit amet rhoncus leo consequat et. M
            </p>
            <button class="bg-blue-700 text-white m-7 p-4 py-3 rounded-lg text-2xl">
                Mint Now
            </button>
        </div>
    </div>

</div>
</template>
<script>
export default{
  name: 'Hero'
}
</script>
<style scoped>
.banner{
  width:100%;
  height: 20vh;
  border-radius: 10px;
  overflow:hidden;
  position: relative;
  /* Create the parallax scrolling effect */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  text-align: center;
}
</style>
