<template>
  <div id="password-protected">
    <div
        class="relative bg-indigo-900 relative w-full h-screen pb-4 text-center min-h-1/2 bg-no-repeat bg-cover bg-center py-10"
        :style="{'background-image': 'url(' + require('@/assets/background.svg') + ')'}">

        <div class="flex flex-col justify-between w-11/12 mx-auto p-3">
            <div class="flex">
                <img src="@/assets/derp.png" class="w-12 h-12 mr-3"/>
                <p class="text-white text-5xl font-bold ">Derpy Dragons</p>
            </div>
        </div>

        <div class="flex flex-col w-10/12 mx-auto py-20 items-center justify-between">
            <div class="text-center">
                <iframe src="https://giphy.com/embed/fol17QeNxajlP6fhPY" width="200" height="200" frameBorder="0" class="giphy-embed  rounded-full border-8 border-white">
                </iframe>
            </div>
            <div class="w-full md:w-10/12">
                <h1 class="text-pink-400 text-3xl font-bold py-5">Welcome to Derpy Dragons</h1>
                <p class="text-sm italic text-md text-white md:text-2xl">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed ac felis congue, accumsan velit vitae, vestibulum elit. Vestibulum nec maximus ipsum, vel mollis libero. Quisque accumsan rutrum massa, eu gravida libero aliquam id. Aliquam dapibus consequat lacus, sit amet rhoncus leo consequat et. M
                </p>
            <p class="text-3xl my-10 mb-0 text-red-500">{{feedback}}</p>
            <form class="flex justify-center w-full items-center md:justify-center">
                <div class="flex items-center mx-auto w-4/5 max-w-md">
                  <input class="border-2 border-primary bg-red transition h-12 px-5 rounded-md focus:outline-none w-full text-black text-lg " :class="{'border-red-500 border-2': feedback != ''}" type="password" name="password" placeholder="Enter password" v-model="site_password" />
                  <button
                      @click="checkPassword()"
                      class="bg-blue-700 text-white m-1 p-1 py-3 w-36 rounded-lg text-md">
                      Enter Site
                  </button>
                </div>
<!--
                <input class="p-2 rounded-lg w-6/12 text-2xl" :class="{'border-red-500 border-2': feedback != ''}" v-model="site_password" type="password" placeholder="Enter site password"/>
                <button
                    @click="checkPassword()"
                    class="bg-blue-700 text-white m-7 p-4 py-3 rounded-lg text-2xl">
                    Enter Site
                </button> -->
            </form>
            </div>
        </div>
  </div>
  <Footer/>
</div>
</template>

<script>
// @ is an alias to /src
import {mapState, mapMutations} from 'vuex'
import Footer from '@/components/Footer.vue'

export default {
  name: 'PasswordProtected',
  components: {
     Footer
  },
  data(){
      return{
        site_password: '',
        processing: false,
        feedback: ''
      }
  },
  methods:{
    ...mapMutations(['SET_PASSWORD']),
    checkPassword(){
        if(this.site_password == 'Wearesecure19!'){
            this.$router.push({name: 'Home'})
            this.SET_PASSWORD(this.site_password)
        }else{
            this.feedback = 'Incorrect Password'
        }
    }
  },
  computed:{
    ...mapState(['site_password'])
  }
}
</script>
