<template>
  <div class="min-h-8 -mt-10 md:-mt-40 relative py-10 bg-purple-300 flex justify-evenly items-center">
      <div>
        <img class="w-20 rounded-full"  src="@/assets/derp.png" />
      </div>
      <div class="flex justify-center ">
        <a class="m-2" href="https://twitter.com/DerpyDragonNFT">
          <img class="w-10" src="@/assets/twitter.svg" />
        </a>
      <a class="m-2" href="https://discord.gg/hm4z38dZ8x">
        <img class="w-10"  src="@/assets/discord.svg" />
      </a>
      </div>
      <div>
        <p class="text-sm">
          © 2021 Derpy Dragons
        </p>
      </div>
  </div>
</template>
<script>
  export default{
    name: 'Footer'
  }
</script>
<style scoped>
</style>
