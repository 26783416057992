import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    site_password: localStorage.getItem('site_password') || '',
  },
  mutations: {
    SET_PASSWORD(state, site_password) {
      state.site_password = site_password
      window.localStorage.setItem('site_password', site_password)
    },
  },
  actions: {
  },
  modules: {
  }
})
