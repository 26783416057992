<template>
  <div class="home">
    <Hero />
    <WhatIs />
    <Roadmap />
    <Footer/>
  </div>
</template>

<script>
// @ is an alias to /src
import Hero from '@/components/Hero.vue'
import WhatIs from '@/components/WhatIs.vue'
import Roadmap from '@/components/Roadmap.vue'
import Footer from '@/components/Footer.vue'
import {mapState} from 'vuex'

export default {
  name: 'Home',
  components: {
    Hero,
    WhatIs,
    Roadmap,
    Footer
  },
  computed:{
    ...mapState(['site_password'])
  },
  created(){
    if(this.site_password == ''){
      this.$router.push({'name': 'PasswordProtected'})
    }
  }
}
</script>
