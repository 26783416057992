<template>
<div
    class="relative bg-gray-900 relative w-full min-h-full bg-no-repeat bg-cover bg-center py-10"
    :style="{'background-image': 'url(' + require('@/assets/bg-1.svg') + ')'}">


    <div class="flex w-10/12 mx-auto py-20 items-center justify-between">
        <div class="w-7/12 mx-auto text-center">
            <h1 class="text-pink-400 text-5xl font-bold">About the Collection</h1>
                <iframe src="https://giphy.com/embed/fol17QeNxajlP6fhPY" width="480" height="480" frameBorder="0" class="giphy-embed mx-auto my-3" allowFullScreen></iframe><p>
                </p>
            <p class="text-lg italic text-xl text-white my-10">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed ac felis congue, accumsan velit vitae, vestibulum elit. Vestibulum nec maximus ipsum, vel mollis libero. Quisque accumsan rutrum massa, eu gravida libero aliquam id. Aliquam dapibus consequat lacus, sit amet rhoncus leo consequat et. M
            </p>
            <p class="text-lg italic text-xl text-white my-10">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed ac felis congue, accumsan velit vitae, vestibulum elit. Vestibulum nec maximus ipsum, vel mollis libero. Quisque accumsan rutrum massa, eu gravida libero aliquam id. Aliquam dapibus consequat lacus, sit amet rhoncus leo consequat et. M
            </p>
            <p class="text-lg italic text-xl text-white my-10">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed ac felis congue, accumsan velit vitae, vestibulum elit. Vestibulum nec maximus ipsum, vel mollis libero. Quisque accumsan rutrum massa, eu gravida libero aliquam id. Aliquam dapibus consequat lacus, sit amet rhoncus leo consequat et. M
            </p>
        </div>
    </div>

</div>
</template>
<script>
export default{
  name: 'Hero'
}
</script>
<style scoped>
.banner{
  width:100%;
  height: 20vh;
  border-radius: 10px;
  overflow:hidden;
  position: relative;
  /* Create the parallax scrolling effect */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  text-align: center;
}
</style>
